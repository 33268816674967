import User from '@/models/User'
import Avatar from '@/models/Avatar'
import Api from '@/services/api'
import { each, find, findIndex } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1
    },
    userDetails: null
  },

  mutations: {
    setUserList(state, users) {
      each(users, user => {
        const exist = find(state.list, { id: user.id })
        if (!exist) {
          state.list.push(new User(user))
        }
      })
    },

    clearUserList(state) {
      state.list = []
    },

    setUserListMeta(state, meta) {
      state.listMeta = meta
    },

    setUserDetails(state, user) {
      if (user instanceof User) {
        state.userDetails = user
      } else {
        state.userDetails = new User(user)
      }
    },

    setSelectedUserAvatar(state, avatar) {
      if (avatar instanceof Avatar) {
        state.userDetails.avatar = avatar
      } else {
        state.userDetails.avatar = new Avatar(avatar)
      }
    },

    clearUserDetails(state) {
      state.userDetails = null
    },

    updateUserList(state, index) {},

    removeUserFromList(state, user) {
      const index = findIndex(state.list, { id: user.id })
      if (index > -1) {
        state.list.splice(index, 1)
      }
    },

    userAvatarRemoved(state) {
      state.userDetails.avatar = null
    }
  },

  actions: {
    async getUsers({ commit }, params) {
      const query = User.page(params.page || 1)
        .include('avatar', 'organisation')
        .orderBy(params.sort)

      if (params.search) {
        query.where('search', params.search)
      }

      if (params.organisation) {
        query.where('organisation_id', params.organisation)
      }

      const res = await query.params({ limit: 20 }).get()

      commit('setUserList', res.data)
      commit('setUserListMeta', res.meta)
    },

    async getFlaggedUsers({ commit }, params) {
      const res = await Api.get('/flag-user', {
        params: { limit: 20, page: params.page }
      })

      commit('setUserList', res.data.data)
      commit('setUserListMeta', res.data.meta)
    },

    async getUserDetails({ commit }, id) {
      const { data } = await Api.get(`users/${id}`)
      commit('setUserDetails', data.data)
    },

    async changeAvatar({ commit }, formData) {
      const { data } = await Api.post(
        `users/${formData.get('user_id')}/avatar`,
        formData
      )
      commit('setSelectedUserAvatar', data.data)
    },

    async createUser({ commit }, formData) {
      const { data } = await Api.post(`users`, formData)
      commit('setUserDetails', data.data)
    },

    async updateUser({ state, commit }, formData) {
      const { data } = await Api.post(`users/${state.userDetails.id}`, formData)
      commit('setUserDetails', data.data)
    },

    async deleteUser({ commit, state }) {
      await Api.delete(`users/${state.userDetails.id}`)
    },

    async exportToExcel() {
      const file = await Api.get('/users/export', { responseType: 'blob' })
      return file
    }
  }
}
