import Organisation from '@/models/Organisation'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1
    },
    organisationDetails: null
  },

  mutations: {
    setOrganisationList(state, organisations) {
      each(organisations, organisation => {
        const exist = find(state.list, { id: organisation.id })
        if (!exist) {
          state.list.push(new Organisation(organisation))
        }
      })
    },

    clearOrganisationList(state) {
      state.list = []
    },

    setOrganisationListMeta(state, meta) {
      state.listMeta = meta
    },

    setOrganisationDetails(state, organisation) {
      if (organisation instanceof Organisation) {
        state.organisationDetails = organisation
      } else {
        state.organisationDetails = new Organisation(organisation)
      }
    },

    clearOrganisationDetails(state) {
      state.organisationDetails = null
    }
  },

  actions: {
    async getOrganisations({ commit }, params = null) {
      const query = new Organisation()

      if (params) {
        if (params.page) {
          query
            .page(params.page)
            .orderBy(params.sort)
            .params({ limit: 20 })
        }

        if (params.search) {
          query.where('search', params.search)
        }

        if (params.type) {
          query.params({ type: params.type })
        }
      }

      const res = await query.get()

      commit('setOrganisationList', res.data)
      commit('setOrganisationListMeta', res.meta)
    },

    async getOrganisationDetails({ commit }, id) {
      const { data } = await Api.get(`organisations/${id}`)
      commit('setOrganisationDetails', data.data)
    },

    async createOrganisation({ commit }, formData) {
      const { data } = await Api.post(`organisations`, formData)
      commit('setOrganisationDetails', data.data)
    },

    async updateOrganisation({ state, commit }, formData) {
      const { data } = await Api.post(
        `organisations/${state.organisationDetails.id}`,
        formData
      )
      commit('setOrganisationDetails', data.data)
    },

    async deleteOrganisation({ state }) {
      await Api.delete(`organisations/${state.organisationDetails.id}`)
    }
  }
}
