import FlaggedUserAction from '@/models/FlaggedUserAction'
import UserAction from '@/models/UserAction'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,

  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1
    },
    flaggedUserActionDetails: null
  },

  mutations: {
    setFlaggedUserActionList(state, flaggedUserActions) {
      each(flaggedUserActions, flaggedUserAction => {
        const exist = find(state.list, { id: flaggedUserAction.id })
        if (!exist) {
          state.list.push(new FlaggedUserAction(flaggedUserAction))
        }
      })
    },

    setFlaggedUserActionListMeta(state, meta) {
      state.listMeta = meta
    },

    setFlaggedUserActionDetails(state, flaggedUserAction) {
      if (flaggedUserAction instanceof FlaggedUserAction) {
        state.flaggedUserActionDetails = flaggedUserAction
      } else {
        state.flaggedUserActionDetails = new FlaggedUserAction(
          flaggedUserAction
        )
      }
    },

    clearFlaggedUserActionList(state) {
      state.list = []
    },

    clearFlaggedUserActionDetails(state) {
      state.flaggedUserActionDetails = null
    }
  },

  actions: {
    async getFlaggedUserActions({ commit }, params) {
      let userAction = new UserAction({ id: params.id })
      let flaggedUserAction = new FlaggedUserAction()

      const query = FlaggedUserAction.custom(
        userAction,
        flaggedUserAction
      ).page(params.page || 1)

      const res = await query.params({ limit: 10 }).get()

      commit('setFlaggedUserActionList', res.data)
      commit('setFlaggedUserActionListMeta', res.meta)
    },

    async getFlaggedUserActionDetails({ commit }, id) {
      const { data } = await Api.get(`flaggedUserActions/${id}`)
      commit('setFlaggedUserActionDetails', data.data)
    }
  }
}
