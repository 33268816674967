import Mission from '@/models/Mission'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1
    },
    missionDetails: null
  },

  mutations: {
    setMissionList(state, missions) {
      each(missions, mission => {
        const exist = find(state.list, { id: mission.id })
        if (!exist) {
          state.list.push(new Mission(mission))
        }
      })
    },

    clearMissionList(state) {
      state.list = []
    },

    setMissionDetails(state, mission) {
      if (mission instanceof Mission) {
        state.missionDetails = mission
      } else {
        state.missionDetails = new Mission(mission)
      }
    },

    clearMissionDetails(state) {
      state.missionDetails = null
    }
  },

  actions: {
    async getMissions({ commit }) {
      const res = await Mission.get()

      commit('setMissionList', res.data)
    },

    async getMissionDetails({ commit }, id) {
      const { data } = await Api.get(`missions/${id}`)
      commit('setMissionDetails', data.data)
    },

    async createMission({ commit }, formData) {
      const { data } = await Api.post(`missions`, formData)
      commit('setMissionDetails', data.data)
    },

    async updateMission({ state, commit }, object) {
      const { data } = await Api.post(
        `missions/${state.missionDetails.id}`,
        object
      )
      commit('setMissionDetails', data.data)
    },

    async deleteMission({ state }) {
      await Api.delete(`mission/${state.missionDetails.id}`)
    }
  }
}
