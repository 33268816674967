import Model from './Model'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default class Challenge extends Model {
  resource() {
    return 'challenges'
  }

  get startDateDisplay() {
    if (this.start_date) {
      return dayjs
        .utc(this.start_date)
        .local()
        .format('MMM DD, YYYY')
    }
  }

  get endDateDisplay() {
    if (this.end_date) {
      return dayjs
        .utc(this.end_date)
        .local()
        .format('MMM DD, YYYY')
    }
  }

  get orgName() {
    if (this.organisation) {
      return this.organisation.name
    }

    return ''
  }
}
