export default {
  state: {
    detail: null
  },

  mutations: {
    setDetail(state, item) {
      state.detail = item
    },

    clearDetail(state) {
      state.detail = null
    }
  }
}
