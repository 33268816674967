import Model from './Model'
import Avatar from './Avatar'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default class User extends Model {
  resource() {
    return 'users'
  }

  avatar() {
    return this.hasMany(Avatar)
  }

  get avatarText() {
    if (this.first_name === null && this.last_name === null) {
      return 'null'
    }

    return (
      (this.first_name ? this.first_name.charAt(0) : '') +
      (this.last_name ? this.last_name.charAt(0) : '')
    )
  }

  get avatarThumb() {
    if (this.avatar === null) {
      return { text: this.avatarText }
    }

    return this.avatar
  }

  get fullName() {
    return this.last_name + ', ' + this.first_name
  }

  get lastUpdate() {
    return dayjs
      .utc(this.updated_at)
      .local()
      .format('MMM DD, YYYY hh:mm A')
  }

  get addedDate() {
    return dayjs
      .utc(this.created_at)
      .local()
      .format('MMM DD, YYYY hh:mm A')
  }

  get organisationName() {
    if (this.organisation) {
      return this.organisation.name
    }

    return null
  }
}
