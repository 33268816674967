import Model from './Model'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default class Charity extends Model {
  resource() {
    return 'charities'
  }

  get addedDate() {
    return dayjs
      .utc(this.createdAt)
      .local()
      .format('MMM DD, YYYY hh:mm A')
  }
}
