export default {
  path: '/signup',
  name: 'signup',
  component: () => import(/* webpackChunkName: "file" */ '@/views/Signup'),
  children: [
    {
      path: '/signup',
      name: 'signup.signup-page',
      component: () =>
        import(
          /* webpackChunkName: "new-file-page" */ '@/views/Signup/SignupPage.vue'
        )
    }
  ]
}
