import Charity from '@/models/Charity'
import Pbp from '@/services/pbp'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 0,
      total: 0
    }
  },

  mutations: {
    setCharityList(state, charities) {
      each(charities, charity => {
        const exist = find(state.list, { id: charity.id })
        if (!exist) {
          state.list.push(new Charity(charity))
        }
      })
    },

    clearCharityList(state) {
      state.list = []
    },

    setCharityListMeta(state, { currentPage, lastPage, total }) {
      state.listMeta = { current_page: currentPage, last_page: lastPage, total }
    }
  },

  actions: {
    async getCharities({ commit }, params) {
      const res = await Pbp.get(`/organisations`, { params })

      const lastPageLink = res.data._links.last
      const lastPage = parseInt(
        lastPageLink.slice(lastPageLink.search('page=') + 5)
      )

      commit('setCharityList', res.data.data)
      commit('setCharityListMeta', {
        currentPage: res.data.pageNumber,
        lastPage: lastPage,
        total: res.data.totalResults
      })
    }
  }
}
