import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import env from './env'

const pbp = axios.create({
  baseURL: env.pbpApi
})

pbp.interceptors.request.use(
  function(config) {
    const publicKey = env.pbpPublicKey
    if (publicKey) {
      config.headers = {
        Authorization: publicKey,
        'Access-Control-Allow-Origin': '*'
      }
    }
    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)

pbp.interceptors.response.use(
  function(response) {
    return response
  },
  function(error) {
    return Promise.reject(error)
  }
)

Vue.prototype.$pbp = pbp
Vuex.Store.prototype.$pbp = pbp

export default pbp
