import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

Vue.use(Vuetify)

const iconfont = 'mdi'

const vuetify = new Vuetify({
  icons: {
    iconfont
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#0A0A0A',
        secondary: '#FFB525',
        accent: '#8c9eff',
        error: '#FA4856',
        success: '#72D62A',
        warning: '#FFD20C',
        grey: {
          base: '#9E9E9E',
          lighten3: '#f1f3f4'
        }
      }
    }
  }
})

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: iconfont
})

export default vuetify
