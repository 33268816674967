import { each, find } from 'lodash'

export default {
  state: {
    list: [],
    listMeta: {
      // refer to laravel paginate meta prop
      current_page: 0,
      last_page: 1
    },
    options: {
      itemsPerPage: 20
    }
  },

  mutations: {
    setOptions(state, options) {
      state.options = options
    },
    mergeList(state, { list, newInstanceFn }) {
      each(list, item => {
        const exist = find(state.list, { id: item.id })
        if (!exist) {
          state.list.push(item)
        }
      })
    },

    clearList(state) {
      state.list = []
      state.listMeta = {
        current_page: 0,
        last_page: 1
      }
    },

    setListMeta(state, meta) {
      state.listMeta = meta
    },

    removeFromList(state, index) {
      state.list.splice(index, 1)
    }
  },

  actions: {
    async getList({ state, commit, dispatch }, payload) {
      if (state.listMeta.current_page >= state.listMeta.last_page) {
        return
      }

      payload.page = state.listMeta.current_page + 1

      const res = await dispatch('performListRequest', payload)

      commit('mergeList', { list: res.data })
      commit('setListMeta', res.meta)
    }
  }
}
