import UserAction from '@/models/UserAction'
// import Api from '@/services/api'
import { each, find, findIndex } from 'lodash'

export default {
  namespaced: true,

  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1
    },
    userActionDetails: null
  },

  mutations: {
    setUserActionList(state, userActions) {
      each(userActions, userAction => {
        const exist = find(state.list, { id: userAction.id })
        if (!exist) {
          state.list.push(new UserAction(userAction))
        }
      })
    },

    setUserActionListMeta(state, meta) {
      state.listMeta = meta
    },

    setUserActionDetails(state, userAction) {
      if (userAction instanceof UserAction) {
        state.userActionDetails = userAction
      } else {
        state.userActionDetails = new UserAction(userAction)
      }
    },

    updateUserActionList(state, index) {
      state.list.splice(index, 1)
    },

    clearUserActionList(state) {
      state.list = []
    },

    clearUserActionDetails(state) {
      state.userActionDetails = null
    },

    removeUserAction(state, userAction) {
      const index = findIndex(state.list, { id: userAction.id })
      if (index > -1) {
        state.list.splice(index, 1)
      }
    }
  },

  actions: {
    async getUserActions({ commit }, params) {
      const query = UserAction.page(params.page || 1)

      const res = await query.params({ limit: 20 }).get()

      commit('setUserActionList', res.data)
      commit('setUserActionListMeta', res.meta)
    },

    async getUserActionDetails({ commit }, id) {
      const { data } = await UserAction.find(id)
      commit('setUserActionDetails', data.data)
    },

    removeUserAction({ commit, state }, id) {
      const index = findIndex(state.list, { id: id })
      if (index > -1) {
        commit('updateUserActionList', index)
      }
    }
  }
}
