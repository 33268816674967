import baseList from './base/base-list'
import baseDetail from './base/base-detail'
import Challenge from '../../models/Challenge'
import buildSort from './utils/buildSort'

export default {
  namespaced: true,
  state: {
    ...baseList.state,
    ...baseDetail.state
  },

  mutations: {
    ...baseList.mutations,
    ...baseDetail.mutations
  },

  actions: {
    ...baseList.actions,
    async performListRequest({ state }, { page, filter }) {
      const query = Challenge.page(page)
        .limit(state.options.itemsPerPage)
        .include(['mission', 'organisation'])
        .orderBy(buildSort(state.options))
        .where('search', filter.search || null)
        .where('parent', filter.parent || -1)

      return query.get()
    },

    async getChallengeDetails({ commit }, id) {
      const { data } = await Challenge.find(id)
      commit('setDetail', data)
    }
  }
}
