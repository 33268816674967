import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import user from './modules/user'
import userAction from './modules/userAction'
import flaggedUserAction from './modules/flaggedUserAction'
import challenge from './modules/challenge'
import mission from './modules/mission'
import organisation from './modules/organisation'
import charity from './modules/charity'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    user,
    challenge,
    userAction,
    flaggedUserAction,
    mission,
    organisation,
    charity
  },
  state: {
    controls: {
      showDrawer: true,
      snackbar: {
        state: false,
        text: 'Snackbar message',
        color: 'success'
      },
      signupCurrenTab: 0
    }
  },
  mutations: {
    toggleDrawer(state, value) {
      state.controls.showDrawer = value
    },
    callSnackbar(state, value) {
      state.controls.snackbar = value
    },
    signupNextTab(state) {
      state.controls.signupCurrenTab += 1
    },
    initiateSignUpTab(state) {
      state.controls.signupCurrenTab = 0
    }
  }
})
